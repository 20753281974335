import React from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import { useDonations } from "context/DonationsContext";
import { DonationCard } from "components/Cards/DonationCard";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";

export const DonationsTab = () => {
	const { recentDonations } = useDonations();

	return (
		<View style={styles.tableContainer}>

			<Table className="table table-borderless">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell style={{ textAlign: "left" }}>Nonprofit</TableCell>
						<TableCell style={{ textAlign: "left" }}>Name</TableCell>
						<TableCell style={{ textAlign: "left" }}>Email</TableCell>
						<TableCell>Frequency</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Date</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{recentDonations &&
						recentDonations.slice(0, 10).map((el, i) => {
							return <DonationCard item={el} key={i} />
						}
						)}
				</TableBody>
			</Table>


		</View>
	)
}

const styles = StyleSheet.create({
	tableContainer: {
		minHeight: 300,
		backgroundColor: COLORS.primary.offWhite,
		overflow: "auto"
	}
});
